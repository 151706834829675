@use '../variables' as *;

.navbar {
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navbarBottomBar {
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: $brand-color;
  position: absolute
}

.headerInner {
  @include page-width();
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  position: relative;
  padding: 20px 0;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
}

.headerLogoBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $brand-color;
  display: flex;
  justify-content: center;
  align-items: center;

  .headerLogoBarInner {
    @include page-width();
  }
}

.logo {
  height: 40px;
}


.buttons {
  display: flex;
  flex-direction: row;
}

.button {
  background-color: #FFF;
  border-radius: 30px;
  overflow: hidden;
  padding: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: none;
  position: relative;
  margin-left: 10px;

  &:hover, &:focus {
    background-color: rgba(#FFF, 0.1);
  }

  .buttonCircle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .buttonStroke {
    fill: none;
    stroke: $brand-color;
  }
}



.signUpMenu {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: #FFF;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px 0;
    margin: 0;
    background: #353e5c;
    box-shadow: 0px 0px 60px rgba(#000, 0.3);
    color: #FFF;
    border: 1px solid rgba(255, 255, 255, 0.6);

    li {
      display: block;
      padding: 5px 40px;
      font-weight: bold;

      a {
        cursor: pointer;
      }

      &:not(:last-child):after {
        display: block;
        content: '';
        width: 90px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        margin: 10px auto 0;
      }
    }
  }
}
