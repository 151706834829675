.container {
  background-color: #FFF;
  padding-top: 60px;

  &:before {
    display: block;
    content: "";
    transform: scaleY(-1);
    background-image: linear-gradient(180deg, rgba(254, 254, 254, 0.00) 0%, rgba(0, 0, 0, 0.25) 100%);
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.inner {
  display: block;
  text-align: left;
}
