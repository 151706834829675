@use "../../variables" as *;

.hero {
  background-image: url("../../assets/bg-top.jpg");
  background-size: cover;
  color: #FFF;
  position: relative;

  background-position: center;
  @include when-big() {
    background-position: right;
  }

  &:after {
    @include till-big {
      content: '';
      display: block;
      background: rgba(#000, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.heroContent {
  @include page-width();

  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 999;

  align-items: center;

  @include when-big() {
    align-items: flex-end;
  }

  h1 {
    font-size: 2.5em;
    line-height: 2.8rem;
  }

  h2 {
    font-size: 1.8em;
    font-weight: 300;
  }

  h1, h2 {
    margin: 3px;
    padding: 0;
  }

  img {
    margin-top: 20px;
  }
}

.bigLogo {
  height: 100px;
  margin-left: 6px;
}

.heroContentTexts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include when-big() {
    width: 600px;
    align-items: flex-start;
    text-align: left;
  }
}
