@use "variables" as *;

.page {
  color: $text-color;
  font-size: 16px;
  line-height: 1.8;
  background-color: #FFF;

  @include when-big() {
    font-size: 20px;
    line-height: 1.5;
  }

  img {
    max-width: calc(100% - 20px);
    margin: 0 10px;

    @include when-big() {
      max-width: none;
    }
  }
}

.app {
  z-index: 100000;

  button {
    text-transform: uppercase;
    cursor: pointer;
    font-family: inherit;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
  }
}

.page {
  position: relative;
  padding-top: 80px;

  &:before {
    display: block;
    content: "";
    transform: scaleY(-1);
    background-image: linear-gradient(180deg, rgba(254, 254, 254, 0.00) 0%, rgba(0, 0, 0, 0.25) 100%);
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.pageInner {
  min-height: 500px;
  text-align: center;
  padding: 120px 0 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include page-width;

  @include when-big() {
    margin-bottom: 70px;
  }
}

.cover {
  margin-top: 15px;
}

.companyBio {
  font-size: 1em;
  font-weight: 300;
  margin: 50px 20px 40px 20px;

  @include when-big() {
    width: 70%;
  }

  a {
    color: inherit;
  }
}

.heading {
  font-size: 2em;
  font-weight: 100;
  margin: 0 20px;

  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background-color: $text-color;
    margin: 0 auto;
    margin-top: 30px;
  }

  h2 {
    background-image: linear-gradient(180deg, #2098b9 0%, #38746a 100%);
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-size: 1.4em;
    line-height: 1.4em;
  }
}

.s-bio {
  margin-bottom: 60px;
}

.contact {
  background-color: #5e5e5e;
  background-image: url('./assets/bg-bottom.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &:after {
    content: '';
    display: block;
    background: rgba(#000, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
  }
}

.contactForm {
  position: relative;
  z-index: 999;
  background-color: #56B71D;
  border-radius: 20px;
  color: #FFF;
  text-align: left;
  padding: 20px 20px;
  font-size: 1em;
  margin-top: -100px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 0px 60px rgba(#FFF, 0.3);
  border: 1px solid rgba(#FFF, 0.2);

  @media screen and (min-width: 800px) {
    width: 800px;
    padding: 20px 40px;
  }

  > * {
    margin: 10px 0;
  }

  h2 {
    margin-bottom: 40px;
  }

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
}

.bottom {
  margin-bottom: 0;
  padding-bottom: 0;
  height: 600px;
  position: relative;
}

.contactRow {
  display: flex;
  flex-direction: column;

  @include when-big() {
    flex-direction: row;
  }
}

.contactCol {
  display: flex;
  flex-direction: column;

  @include when-big() {
    width: 50%;
  }
}

.copy {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  @include page-width();
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.7em;
  display: flex;
  z-index: 400;

  flex-direction: column;
  @include when-big() {
    flex-direction: row;
  }

  a {
    color: inherit;
  }

  &Col {
    @include when-big() {
      width: 50%;
    }

    &:first-child {
      text-align: center;

      @include when-big() {
        text-align: left;
      }
    }

    &:last-child {
      text-align: center;

      @include when-big() {
        text-align: right;
      }
    }
  }
}

.linkButton {
  background-color: transparent;
  border: 0;
  color: #FFF;
  padding: 0;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: inherit;
  font-family: inherit;
  font-weight: bold;
  margin-right: 6px;

  &:not(:first-child) {
    margin-left: 6px;
  }
}

.inputField {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  input {
    padding: 10px;
    border: 0;
    border-radius: 5px;
    background-color: "#FFF";
    color: #000;
    font-size: 1em;
    outline: none;
    font-family: inherit;
    margin: 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .fName {
    margin-bottom: 6px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .fField {
    width: 100%;
  }
}